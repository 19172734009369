
import { defineComponent } from 'vue';
import { dateFormat } from '@/utils/date-util';

export default defineComponent({
  name: 'ArticleListContent',
  props: {
    content: {
      type: String,
      default: '',
    },
    owner: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    updateAt: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      dateFormat,
    };
  },
});
