
import { defineComponent } from 'vue';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { queryFakeList } from '@/api/list/basic-list';
import ArticleListContent from './article-list-content.vue';
import IconText from './icon-text.vue';

export default defineComponent({
  name: 'Articles',
  setup() {
    const { reload, context } = useFetchData(() => {
      return new Promise<any>((resolve, reject) => {
        queryFakeList({ count: 16 })
          .then(list => {
            resolve({
              data: list,
              success: true,
              total: 16,
            });
          })
          .catch(reject);
      });
    });

    return {
      reload,
      context,
    };
  },
  components: {
    ArticleListContent,
    IconText,
  },
});
